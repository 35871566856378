var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ax-reports",attrs:{"fluid":""}},[_c('ax-zone-breadcrumbs'),_c('v-card-text',[_c('h1',[_vm._v(_vm._s(_vm.$t('reports.reports:heading')))]),_c('hr',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"7"}},[_c('ax-card',{staticClass:"mb-4",attrs:{"type":"storm3","data-test-id":"activity-log","to":{ path: '/reports/activity-log', query: { o: _vm.orgId } },"ripple":false},on:{"click":function($event){return _vm.trackAnalytics('reports/activity-log')}}},[_c('div',{staticClass:"ax-reports__card d-flex flex-row justify-space-between"},[_c('div',{staticClass:"ax-reports__card_content"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reports.activityLog.heading'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('reports.activityLog.description'))+" ")])],1),_c('v-card-actions',{staticClass:"ax-reports__card_action justify-center p-4"},[_c('ax-button',{attrs:{"mode":"secondary","width":"10rem","large":""}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" ")])],1)],1)])],1),(_vm.viewPolicyResultsPage)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"7"}},[_c('ax-card',{staticClass:"mb-4",attrs:{"type":"storm3","to":{ name: 'policyResults', query: { o: _vm.orgId } }},on:{"click":function($event){_vm.trackAnalytics('reports/policy-results', {
              role: _vm.getRoleNameInOrg(_vm.user, _vm.orgId),
            })}}},[_c('div',{staticClass:"ax-reports__card d-flex flex-row justify-space-between"},[_c('div',{staticClass:"ax-reports__card_content"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reports.policyResults.heading'))+" "),_c('ax-chip',{staticClass:"ml-3",attrs:{"color":_vm.CosmosDarkColors.Teal}},[_vm._v(" "+_vm._s(_vm.$t('general.phrases.new'))+" ")])],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('reports.policyResults.description'))+" ")])],1),_c('v-card-actions',{staticClass:"ax-reports__card_action justify-center p-4"},[_c('ax-button',{attrs:{"mode":"secondary","width":"10rem","large":""}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" ")])],1)],1)])],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"7"}},[_c('ax-card',{staticClass:"mb-4",attrs:{"type":"storm3","data-test-id":"overview-report","ripple":false,"to":{
            name: 'overview',
            query: { o: _vm.orgId },
          }},on:{"click":function($event){return _vm.trackAnalytics('reports/overview')}}},[_c('div',{staticClass:"ax-reports__card d-flex flex-row justify-space-between"},[_c('div',{staticClass:"ax-reports__card_content"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reports.overview.heading'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('reports.overview.description'))+" ")])],1),_c('v-card-actions',{staticClass:"ax-reports__card_action justify-center p-4"},[_c('ax-button',{attrs:{"mode":"secondary","width":"10rem","large":""}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" ")])],1)],1)])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"7"}},[_c('ax-card',{staticClass:"mb-4",attrs:{"type":"storm3","data-test-id":"needs-attention-report","ripple":false},on:{"click":function($event){return _vm.openSelectGroupModal({
              allowAllGroups: true,
              path: 'reports/needs-attention',
            })}}},[_c('div',{staticClass:"ax-reports__card d-flex flex-row justify-space-between"},[_c('div',{staticClass:"ax-reports__card_content"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reports.needsAttention.heading'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('reports.needsAttention.description'))+" ")])],1),_c('v-card-actions',{staticClass:"ax-reports__card_action justify-center p-4"},[_c('ax-button',{attrs:{"mode":"secondary","width":"10rem","large":""}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" ")])],1)],1)])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"7"}},[_c('ax-card',{staticClass:"mb-4",attrs:{"type":"storm3","data-test-id":"pre-patch-report","ripple":false},on:{"click":function($event){return _vm.openSelectGroupModal({
              allowAllGroups: true,
              path: 'reports/prepatch',
            })}}},[_c('div',{staticClass:"ax-reports__card d-flex flex-row justify-space-between"},[_c('div',{staticClass:"ax-reports__card_content"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reports.prepatch.heading'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('reports.prepatch.description'))+" ")])],1),_c('v-card-actions',{staticClass:"ax-reports__card_action justify-center p-4"},[_c('ax-button',{attrs:{"mode":"secondary","width":"10rem","large":""}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" ")])],1)],1)])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"7"}},[_c('ax-card',{staticClass:"mb-4",attrs:{"type":"storm3","data-test-id":"data-extract-report","ripple":false,"to":{ path: '/reports/data-extracts', query: { o: _vm.orgId } }},on:{"click":function($event){return _vm.trackAnalytics('reports/data-extracts')}}},[_c('div',{staticClass:"ax-reports__card d-flex flex-row justify-space-between"},[_c('div',{staticClass:"ax-reports__card_content"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reports.dataExtracts.heading'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('reports.dataExtracts.description'))+" ")])],1),_c('v-card-actions',{staticClass:"ax-reports__card_action justify-center p-4"},[_c('ax-button',{attrs:{"mode":"secondary","width":"10rem","large":""}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" ")])],1)],1)])],1),_c('ax-modal',{attrs:{"width":"43rem","content-class":"reports-group-modal"},model:{value:(_vm.modals.selectGroup.opened),callback:function ($$v) {_vm.$set(_vm.modals.selectGroup, "opened", $$v)},expression:"modals.selectGroup.opened"}},[_c('ax-reports-select-group-modal',{attrs:{"device-groups":_vm.deviceGroups,"path":_vm.selectGroupConfig.path,"allow-all-groups":_vm.selectGroupConfig.allowAllGroups},on:{"close-modal":_vm.modals.selectGroup.close}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }